var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          ref: "formCreateClass",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _vm.course
            ? _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-1",
                        attrs: { header: "Informações do curso" },
                      },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("b-form-group", [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("strong", [_vm._v("Título")]),
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.course.title) + " "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("b-form-group", [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("strong", [
                                      _vm._v("Título - "),
                                      _c("small", [
                                        _vm._v("Exibido no contrato"),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.course.title_contract) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("b-form-group", [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("strong", [_vm._v("Escolaridade")]),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.course.scholarity.title) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("b-form-group", { staticClass: "mb-0" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("strong", [_vm._v("Tipo")]),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.course.type.title) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1", attrs: { header: "Disciplinas" } },
                      [
                        _c("b-card-text", [
                          _vm.course.disciplines.length
                            ? _c(
                                "ul",
                                {
                                  staticClass: "list-disciplines p-0 mb-0 mt-1",
                                },
                                _vm._l(
                                  _vm.course.disciplines,
                                  function (discipline, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: "discipline-key-" + index,
                                        staticClass: "form-row pb-1",
                                        attrs: { id: "discipline-id-" + index },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-8 text-left" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  discipline.discipline.title
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c(
                                              "b-badge",
                                              { attrs: { variant: "danger" } },
                                              [
                                                discipline.dipe
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center",
                                                        staticStyle: {
                                                          "column-gap": "5px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.top",
                                                                value:
                                                                  "Disciplina inserida pós edital",
                                                                expression:
                                                                  "\n                          'Disciplina inserida pós edital'\n                        ",
                                                                modifiers: {
                                                                  hover: true,
                                                                  top: true,
                                                                },
                                                              },
                                                            ],
                                                            attrs: {
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                              viewBox:
                                                                "0 0 24 24",
                                                              width: "15",
                                                              height: "15",
                                                              color: "#fff",
                                                              fill: "none",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                              },
                                                            }),
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M11.992 16H12.001",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            }),
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M12 13L12 8.99997",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                discipline.dipe
                                                                  ? "DIPE"
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-2 text-center",
                                          },
                                          [
                                            _c(
                                              "b-badge",
                                              {
                                                attrs: {
                                                  variant: "light-primary",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      discipline.workload
                                                    ) +
                                                    "h "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("p", { staticClass: "mb-0 mt-1" }, [
                                _vm._v(
                                  " Nenhuma disciplina vinculado ao curso até o momento. "
                                ),
                              ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Informações básicas" },
                  },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Curso "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid": _vm.$v.item.course.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsCourses,
                                    },
                                    on: {
                                      search: _vm.fetchCourse,
                                      input: _vm.courseSelected,
                                    },
                                    model: {
                                      value: _vm.item.course,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "course", $$v)
                                      },
                                      expression: "item.course",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Modalidade "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid": _vm.$v.item.modality.$error,
                                    },
                                    attrs: {
                                      searchable: false,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsModalities,
                                    },
                                    model: {
                                      value: _vm.item.modality,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "modality", $$v)
                                      },
                                      expression: "item.modality",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Turno "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid": _vm.$v.item.shift.$error,
                                    },
                                    attrs: {
                                      searchable: false,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsShifts,
                                    },
                                    model: {
                                      value: _vm.item.shift,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "shift", $$v)
                                      },
                                      expression: "item.shift",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("b-form-group", [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Código "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.name,
                                    expression: "item.name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.item.name.$error,
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Início "),
                              _c("i", { staticClass: "bi bi-clock" }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.start_time,
                                  expression: "item.start_time",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##:##"],
                                  expression: "['##:##']",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.$v.item.start_time.$error,
                              },
                              attrs: { placeholder: "HH:mm", type: "tel" },
                              domProps: { value: _vm.item.start_time },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "start_time",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Término "),
                              _c("i", { staticClass: "bi bi-clock" }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.end_time,
                                  expression: "item.end_time",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##:##"],
                                  expression: "['##:##']",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.$v.item.end_time.$error,
                              },
                              attrs: { placeholder: "HH:mm", type: "tel" },
                              domProps: { value: _vm.item.end_time },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "end_time",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Intervalo "),
                              _c("i", { staticClass: "bi bi-clock" }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.interval,
                                  expression: "item.interval",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##:##"],
                                  expression: "['##:##']",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.$v.item.interval.$error,
                              },
                              attrs: { placeholder: "HH:mm", type: "tel" },
                              domProps: { value: _vm.item.interval },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "interval",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Acesso até "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value:
                                        "Defina até quando aluno poderá ter acesso as aulas. Exceto para alunos passaporte.",
                                      expression:
                                        "\n                        'Defina até quando aluno poderá ter acesso as aulas. Exceto para alunos passaporte.'\n                      ",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "bi bi-question-circle",
                                }),
                              ]),
                              _c("flat-pickr", {
                                staticClass: "form-control",
                                attrs: {
                                  config: {
                                    altInput: true,
                                    dateFormat: "Y-m-d",
                                    altFormat: "d/m/Y",
                                    locale: "pt",
                                  },
                                },
                                model: {
                                  value: _vm.item.date_end,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "date_end", $$v)
                                  },
                                  expression: "item.date_end",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Está confirmada? "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    searchable: false,
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: [
                                      { code: 1, title: "Sim" },
                                      { code: 0, title: "Não" },
                                    ],
                                  },
                                  model: {
                                    value: _vm.item.confirmed,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "confirmed", $$v)
                                    },
                                    expression: "item.confirmed",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.item.modality.title === "Presencial"
                          ? _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Unidade "),
                                    ]),
                                    _c(
                                      "v-select",
                                      {
                                        class: {
                                          "is-invalid": _vm.$v.item.unit.$error,
                                        },
                                        attrs: {
                                          label: "title",
                                          searchable: false,
                                          "item-text": "title",
                                          "item-value": "code",
                                          placeholder: "Digite o título",
                                          options: _vm.optionsUnits,
                                        },
                                        model: {
                                          value: _vm.item.unit,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "unit", $$v)
                                          },
                                          expression: "item.unit",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "no-options" },
                                            slot: "no-options",
                                          },
                                          [
                                            _vm._v(
                                              " Nenhum registro encontrado "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "b-card-footer",
                      { staticClass: "p-0 pt-2 footer-class" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-row justify-content-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-6 col-7" },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "custom-control-primary",
                                        attrs: { value: 1 },
                                        model: {
                                          value: _vm.item.display_panel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "display_panel",
                                              $$v
                                            )
                                          },
                                          expression: "item.display_panel",
                                        },
                                      },
                                      [_vm._v(" Exibir no painel de vendas ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-5" },
                              [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                  staticStyle: {
                                    float: "left",
                                    "line-height": "3.1",
                                    "margin-right": "10px",
                                  },
                                }),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("v-select", {
                                      class: {
                                        "is-invalid": _vm.$v.item.status.$error,
                                      },
                                      attrs: {
                                        searchable: false,
                                        label: "title",
                                        options: [
                                          { title: "Ativo", code: 1 },
                                          { title: "Inativo", code: 0 },
                                        ],
                                      },
                                      model: {
                                        value: _vm.item.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "status", $$v)
                                        },
                                        expression: "item.status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.item.modality.title === "Live"
                  ? _c(
                      "b-card",
                      {
                        staticClass: "mb-1 card-header-custom-actions",
                        attrs: { "header-tag": "header" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "mb-0",
                                      staticStyle: {
                                        "line-height": "1.5",
                                        "font-weight": "500",
                                        "font-size": "1.1rem",
                                        color: "#6e6b7b",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Link da Live "),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          214416841
                        ),
                      },
                      [
                        _c("b-form-group", { staticClass: "mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.link,
                                expression: "item.link",
                              },
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.link.$error },
                            attrs: { placeholder: "https://...." },
                            domProps: { value: _vm.item.link },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.item, "link", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.course && _vm.course.installments.length
                  ? _c(
                      "b-card",
                      {
                        staticClass: "mb-1 card-header-custom-actions",
                        attrs: { "header-tag": "header" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("h4", { staticClass: "mb-0" }, [
                                    _vm._v("Parcelas"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1141745094
                        ),
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-row-header-repeat",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-1 text-center d-none d-sm-block",
                                  },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Nº"),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Valor"),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Parcelas x Valor",
                                        expression: "'Parcelas x Valor'",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass:
                                      "col-md-2 text-center d-none d-sm-block",
                                  },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Total"),
                                    ]),
                                  ]
                                ),
                                _vm._l(
                                  _vm.payment_forms,
                                  function (itemFPayment, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "col-md-1 col-1 text-center",
                                        attrs: { id: index },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: itemFPayment.title,
                                                expression:
                                                  "itemFPayment.title",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _c("i", {
                                                  class: itemFPayment.icon,
                                                  staticStyle: {
                                                    "font-size":
                                                      "1.7rem !important",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                !_vm.payment_forms.length
                                  ? _c("p", [
                                      _vm._v(
                                        " Nenhuma parcela cadastrada até o momento. "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._l(
                              _vm.course.installments,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "installment-key-" + index,
                                    staticClass: "form-row bg",
                                    attrs: { id: "installment-id-" + index },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-2 col-md-1 text-center d-none d-sm-block",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group mb-0",
                                            staticStyle: {
                                              "line-height": "3",
                                              "margin-bottom": "0px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(_vm._s(index + 1) + "x"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-2 col-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group mb-0",
                                            staticStyle: {
                                              "margin-top": "7px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    item.value
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Parcelas x Valor",
                                            expression: "'Parcelas x Valor'",
                                            modifiers: {
                                              hover: true,
                                              top: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "col-md-2 text-center d-none d-sm-block",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group mb-0",
                                            staticStyle: {
                                              "line-height": "4",
                                              "margin-bottom": "0px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    (index + 1) * item.value
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Dinheiro",
                                                expression: "'Dinheiro'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.money
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Cartão de débito",
                                                expression:
                                                  "'Cartão de débito'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.card_debit
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Cartão de crédito",
                                                expression:
                                                  "'Cartão de crédito'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.card_credit
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Pix",
                                                expression: "'Pix'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.pix
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Cheque",
                                                expression: "'Cheque'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.check
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Carta de Crédito",
                                                expression:
                                                  "'Carta de Crédito'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.letter
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-1 col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Boleto",
                                                expression: "'Boleto'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "form-group mb-0",
                                          },
                                          [
                                            item.billet
                                              ? _c("i", {
                                                  staticClass:
                                                    "bi bi-check text-success",
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1 card-header-custom-actions",
                    attrs: { "header-tag": "header" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Dias de aula "),
                                ]),
                                _c("small", [
                                  _vm._v("Habilite os dias de aula"),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "d-block" },
                        _vm._l(_vm.optionsWorkDays, function (element, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "form-group" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    checked: true,
                                    name: "day-" + index,
                                    switch: "",
                                    id: "day-" + index,
                                    inline: "",
                                    value: element.code,
                                  },
                                  model: {
                                    value: _vm.item.weekdays,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "weekdays", $$v)
                                    },
                                    expression: "item.weekdays",
                                  },
                                },
                                [_vm._v(" " + _vm._s(element.title) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1 card-header-custom-actions",
                    attrs: { "header-tag": "header" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("h4", { staticClass: "mb-0" }, [
                              _vm._v("ID da Grade Consys"),
                            ]),
                            _c("small", [
                              _vm._v("Cadastre o "),
                              _c("strong", [_vm._v("ID da Grade ")]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-card-text", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.consys_grid,
                            expression: "item.consys_grid",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.consys_grid },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "consys_grid",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1 card-header-custom-actions",
                    attrs: { "header-tag": "header" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-9 col-8" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _vm._v("Turma(s) no Consys"),
                                ]),
                                _c("small", [
                                  _vm._v("Cadastre o "),
                                  _c("strong", [_vm._v("ID da Turma")]),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-3 col-4" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      staticClass: "btn-block",
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.repeateAgainClassrooms },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-plus mr-25",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-form",
                          {
                            staticClass: "repeater-form",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.repeateAgainClassrooms.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.item.classrooms,
                            function (element, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "form-group",
                                  class:
                                    _vm.item.classrooms.length === index + 1
                                      ? "mb-0"
                                      : "",
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-md-9" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: element.code,
                                            expression: "element.code",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "ID da turma",
                                        },
                                        domProps: { value: element.code },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              element,
                                              "code",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple.400",
                                              value: "rgba(234, 84, 85, 0.15)",
                                              expression:
                                                "'rgba(234, 84, 85, 0.15)'",
                                              modifiers: { 400: true },
                                            },
                                          ],
                                          staticClass:
                                            "mt-0 btn btn-block btn-outline-danger",
                                          staticStyle: { "line-height": "1.3" },
                                          attrs: { type: "button", block: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeItemClassrooms(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_c("i", { staticClass: "bi bi-x" })]
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1 card-header-custom-actions",
                    attrs: { "header-tag": "header" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-8 col-8" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Previsão de Início "),
                                ]),
                                _c("small", [
                                  _vm._v("Cadastre 1 ou mais datas"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-4 col-4" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      staticClass: "btn-block",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: _vm.repeateAgainPostponements,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-plus mr-25",
                                      }),
                                      _c("span", [_vm._v("Novo")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "p",
                          {
                            class:
                              _vm.item.postponements.length > 0
                                ? "mb-4"
                                : "mb-0",
                          },
                          [
                            _vm._v(
                              " A última data cadastrada, será considerada a próxima data de início. "
                            ),
                          ]
                        ),
                        _c(
                          "b-form",
                          {
                            staticClass: "repeater-form",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.repeateAgainPostponements.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "app-timeline",
                              _vm._l(
                                _vm.item.postponements,
                                function (element, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      attrs: { id: "post-" + index },
                                    },
                                    [
                                      _c(
                                        "app-timeline-item",
                                        { attrs: { icon: "calendar-event" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "9", lg: "9" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group mb-1",
                                                    },
                                                    [
                                                      _c("flat-pickr", {
                                                        staticClass:
                                                          "form-control",
                                                        class:
                                                          index !==
                                                          _vm.item.postponements
                                                            .length -
                                                            1
                                                            ? "disabled"
                                                            : "",
                                                        attrs: {
                                                          disabled:
                                                            index !==
                                                            _vm.item
                                                              .postponements
                                                              .length -
                                                              1
                                                              ? true
                                                              : false,
                                                          config: {
                                                            altInput: true,
                                                            dateFormat: "Y-m-d",
                                                            altFormat: "d/m/Y",
                                                            locale: "pt",
                                                          },
                                                        },
                                                        model: {
                                                          value: element.date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              element,
                                                              "date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "element.date",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              index ===
                                              _vm.item.postponements.length - 1
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        lg: "3",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group mb-0",
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "ripple",
                                                                  rawName:
                                                                    "v-ripple.400",
                                                                  value:
                                                                    "rgba(234, 84, 85, 0.15)",
                                                                  expression:
                                                                    "'rgba(234, 84, 85, 0.15)'",
                                                                  modifiers: {
                                                                    400: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "mt-0",
                                                              staticStyle: {
                                                                "line-height":
                                                                  "1.3",
                                                              },
                                                              attrs: {
                                                                variant:
                                                                  "outline-danger",
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeItemPostponements(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "bi bi-x-square",
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "classrooms-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Cadastrar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }