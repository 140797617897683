<template>
  <div>
    <b-form ref="formCreateClass" @submit.prevent="submitCreate">
      <div v-if="course" class="form-row">
        <div class="col-md-4">
          <b-card header="Informações do curso" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12">
                  <b-form-group>
                    <label for=""><strong>Título</strong></label>
                    {{ course.title }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="">
                      <strong
                        >Título - <small>Exibido no contrato</small></strong
                      >
                    </label>
                    {{ course.title_contract }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="">
                      <strong>Escolaridade</strong>
                    </label>
                    {{ course.scholarity.title }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group class="mb-0">
                    <label for="">
                      <strong>Tipo</strong>
                    </label>
                    {{ course.type.title }}
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-md-8">
          <b-card header="Disciplinas" class="mb-1">
            <b-card-text>
              <ul
                class="list-disciplines p-0 mb-0 mt-1"
                v-if="course.disciplines.length"
              >
                <li
                  class="form-row pb-1"
                  v-for="(discipline, index) in course.disciplines"
                  :id="`discipline-id-${index}`"
                  :key="`discipline-key-${index}`"
                >
                  <div class="col-md-8 text-left">
                    {{ discipline.discipline.title }}
                  </div>

                  <div class="col-md-2">
                    <b-badge variant="danger">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        v-if="discipline.dipe"
                        style="column-gap: 5px"
                      >
                        <svg
                          v-b-tooltip.hover.top="
                            'Disciplina inserida pós edital'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          color="#fff"
                          fill="none"
                        >
                          <path
                            d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z"
                            stroke="currentColor"
                            stroke-width="2"
                          />
                          <path
                            d="M11.992 16H12.001"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 13L12 8.99997"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>
                          {{ discipline.dipe ? "DIPE" : "" }}
                        </span>
                      </div>
                    </b-badge>
                  </div>

                  <div class="col-md-2 text-center">
                    <b-badge variant="light-primary">
                      {{ discipline.workload }}h
                    </b-badge>
                  </div>
                </li>
              </ul>

              <p class="mb-0 mt-1" v-else>
                Nenhuma disciplina vinculado ao curso até o momento.
              </p>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-8">
          <b-card header="Informações básicas" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-9">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Curso
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.course"
                      placeholder="Digite o título"
                      :options="optionsCourses"
                      @search="fetchCourse"
                      :class="{ 'is-invalid': $v.item.course.$error }"
                      @input="courseSelected"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Modalidade
                    </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.modality"
                      placeholder="Digite o título"
                      :options="optionsModalities"
                      :class="{ 'is-invalid': $v.item.modality.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Turno
                    </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.shift"
                      placeholder="Digite o título"
                      :options="optionsShifts"
                      :class="{ 'is-invalid': $v.item.shift.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Código
                    </label>
                    <input
                      type="text"
                      v-model="item.name"
                      class="form-control"
                      :class="{ 'is-invalid': $v.item.name.$error }"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Início <i class="bi bi-clock"></i>
                    </label>
                    <input
                      placeholder="HH:mm"
                      v-model="item.start_time"
                      class="form-control"
                      type="tel"
                      v-mask="['##:##']"
                      :class="{ 'is-invalid': $v.item.start_time.$error }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Término
                      <i class="bi bi-clock"></i>
                    </label>
                    <input
                      placeholder="HH:mm"
                      v-model="item.end_time"
                      class="form-control"
                      type="tel"
                      v-mask="['##:##']"
                      :class="{ 'is-invalid': $v.item.end_time.$error }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Intervalo
                      <i class="bi bi-clock"></i>
                    </label>
                    <input
                      placeholder="HH:mm"
                      v-model="item.interval"
                      class="form-control"
                      type="tel"
                      v-mask="['##:##']"
                      :class="{ 'is-invalid': $v.item.interval.$error }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label>
                      <i class="text-danger bi bi-record-circle"></i>
                      Acesso até
                      <i
                        class="bi bi-question-circle"
                        v-b-tooltip.hover.top="
                          'Defina até quando aluno poderá ter acesso as aulas. Exceto para alunos passaporte.'
                        "
                      ></i>
                    </label>
                    <flat-pickr
                      v-model="item.date_end"
                      class="form-control"
                      :config="{
                        altInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd/m/Y',
                        locale: 'pt',
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label>
                      <i class="text-danger bi bi-record-circle"></i>
                      Está confirmada?
                    </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.confirmed"
                      placeholder="Digite o título"
                      :options="[
                        { code: 1, title: 'Sim' },
                        { code: 0, title: 'Não' },
                      ]"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div
                  class="col-md-6"
                  v-if="item.modality.title === 'Presencial'"
                >
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Unidade
                    </label>
                    <v-select
                      label="title"
                      :searchable="false"
                      item-text="title"
                      item-value="code"
                      v-model="item.unit"
                      placeholder="Digite o título"
                      :options="optionsUnits"
                      :class="{ 'is-invalid': $v.item.unit.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
            <b-card-footer class="p-0 pt-2 footer-class">
              <div class="form-row justify-content-between">
                <div class="col-md-6 col-7">
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.display_panel"
                      :value="1"
                      class="custom-control-primary"
                    >
                      Exibir no painel de vendas
                    </b-form-checkbox>
                  </b-form-group>
                </div>

                <div class="col-md-4 col-5">
                  <i
                    style="float: left; line-height: 3.1; margin-right: 10px"
                    class="text-danger bi bi-record-circle"
                  ></i>
                  <b-form-group class="mb-0">
                    <v-select
                      :searchable="false"
                      label="title"
                      v-model="item.status"
                      :class="{ 'is-invalid': $v.item.status.$error }"
                      :options="[
                        { title: 'Ativo', code: 1 },
                        { title: 'Inativo', code: 0 },
                      ]"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-card-footer>
          </b-card>

          <b-card
            v-if="item.modality.title === 'Live'"
            class="mb-1 card-header-custom-actions"
            header-tag="header"
          >
            <template #header>
              <h4
                class="mb-0"
                style="
                  line-height: 1.5;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: #6e6b7b;
                "
              >
                <i class="text-danger bi bi-record-circle"></i>
                Link da Live
              </h4>
            </template>
            <b-form-group class="mb-0">
              <input
                class="form-control"
                v-model="item.link"
                placeholder="https://...."
                :class="{ 'is-invalid': $v.item.link.$error }"
              />
            </b-form-group>
          </b-card>

          <b-card
            v-if="course && course.installments.length"
            class="mb-1 card-header-custom-actions"
            header-tag="header"
          >
            <template #header>
              <h4 class="mb-0">Parcelas</h4>
            </template>

            <b-card-text>
              <div class="form-row form-row-header-repeat">
                <div class="col-md-1 text-center d-none d-sm-block">
                  <label for="">Nº</label>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Valor</label>
                  </div>
                </div>

                <div
                  class="col-md-2 text-center d-none d-sm-block"
                  v-b-tooltip.hover.top="'Parcelas x Valor'"
                >
                  <label for="">Total</label>
                </div>

                <div
                  v-for="(itemFPayment, index) in payment_forms"
                  :key="index"
                  :id="index"
                  class="col-md-1 col-1 text-center"
                >
                  <div
                    class="form-group"
                    v-b-tooltip.hover.top="itemFPayment.title"
                  >
                    <label for="">
                      <i
                        style="font-size: 1.7rem !important"
                        :class="itemFPayment.icon"
                      ></i>
                    </label>
                  </div>
                </div>

                <p v-if="!payment_forms.length">
                  Nenhuma parcela cadastrada até o momento.
                </p>
              </div>

              <div
                class="form-row bg"
                v-for="(item, index) in course.installments"
                :id="`installment-id-${index}`"
                :key="`installment-key-${index}`"
              >
                <div class="col-2 col-md-1 text-center d-none d-sm-block">
                  <div
                    class="form-group mb-0"
                    style="line-height: 3; margin-bottom: 0px"
                  >
                    <strong>{{ index + 1 }}x</strong>
                  </div>
                </div>

                <div class="col-md-2 col-4">
                  <div class="form-group mb-0" style="margin-top: 7px">
                    {{ item.value | toCurrency }}
                  </div>
                </div>

                <div
                  class="col-md-2 text-center d-none d-sm-block"
                  v-b-tooltip.hover.top="'Parcelas x Valor'"
                >
                  <div
                    class="form-group mb-0"
                    style="line-height: 4; margin-bottom: 0px"
                  >
                    {{ ((index + 1) * item.value) | toCurrency }}
                  </div>
                </div>

                <div class="col-md-1 col-2 text-center">
                  <div
                    class="form-group mb-0"
                    v-b-tooltip.hover.top="'Dinheiro'"
                  >
                    <i
                      v-if="item.money"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>

                <div class="col-md-1 col-2 text-center">
                  <div
                    class="form-group mb-0"
                    v-b-tooltip.hover.top="'Cartão de débito'"
                  >
                    <i
                      v-if="item.card_debit"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>

                <div class="col-md-1 col-2 text-center">
                  <div
                    class="form-group mb-0"
                    v-b-tooltip.hover.top="'Cartão de crédito'"
                  >
                    <i
                      v-if="item.card_credit"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>

                <div class="col-md-1 col-2 text-center">
                  <div class="form-group mb-0" v-b-tooltip.hover.top="'Pix'">
                    <i
                      v-if="item.pix"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>

                <div class="col-md-1 col-2 text-center">
                  <div class="form-group mb-0" v-b-tooltip.hover.top="'Cheque'">
                    <i
                      v-if="item.check"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>
                <div class="col-md-1 col-2 text-center">
                  <div
                    class="form-group mb-0"
                    v-b-tooltip.hover.top="'Carta de Crédito'"
                  >
                    <i
                      v-if="item.letter"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>
                <div class="col-md-1 col-2 text-center">
                  <div class="form-group mb-0" v-b-tooltip.hover.top="'Boleto'">
                    <i
                      v-if="item.billet"
                      class="bi bi-check text-success"
                      style="font-size: 1.6rem"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-12">
                  <h4 class="mb-0">
                    <i class="text-danger bi bi-record-circle"></i>
                    Dias de aula
                  </h4>
                  <small>Habilite os dias de aula</small>
                </div>
              </div>
            </template>
            <b-card-text>
              <div class="d-block">
                <div
                  class="form-group"
                  v-for="(element, index) in optionsWorkDays"
                  :key="index"
                >
                  <b-form-checkbox
                    :checked="true"
                    :name="`day-${index}`"
                    switch
                    :id="`day-${index}`"
                    inline
                    v-model="item.weekdays"
                    :value="element.code"
                  >
                    {{ element.title }}
                  </b-form-checkbox>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <h4 class="mb-0">ID da Grade Consys</h4>
              <small>Cadastre o <strong>ID da Grade </strong></small>
            </template>
            <b-card-text>
              <input
                type="text"
                class="form-control"
                v-model="item.consys_grid"
              />
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-9 col-8">
                  <h4 class="mb-0">Turma(s) no Consys</h4>
                  <small>Cadastre o <strong>ID da Turma</strong></small>
                </div>
                <div class="col-md-3 col-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgainClassrooms"
                    class="btn-block"
                  >
                    <i class="bi bi-plus mr-25"></i>
                  </b-button>
                </div>
              </div>
            </template>
            <b-card-text>
              <b-form
                class="repeater-form"
                @submit.prevent="repeateAgainClassrooms"
              >
                <div
                  v-for="(element, index) in item.classrooms"
                  class="form-group"
                  :class="item.classrooms.length === index + 1 ? 'mb-0' : ''"
                  :key="index"
                >
                  <div class="form-row">
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        v-model="element.code"
                        placeholder="ID da turma"
                      />
                    </div>

                    <div class="col-md-3">
                      <button
                        type="button"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="mt-0 btn btn-block btn-outline-danger"
                        block
                        @click="removeItemClassrooms(index)"
                        style="line-height: 1.3"
                      >
                        <i class="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-form>
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-8 col-8">
                  <h4 class="mb-0">
                    <i class="text-danger bi bi-record-circle"></i>
                    Previsão de Início
                  </h4>
                  <small>Cadastre 1 ou mais datas</small>
                </div>
                <div class="col-md-4 col-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgainPostponements"
                    class="btn-block"
                  >
                    <i class="bi bi-plus mr-25"></i>
                    <span>Novo</span>
                  </b-button>
                </div>
              </div>
            </template>
            <b-card-text>
              <p :class="item.postponements.length > 0 ? 'mb-4' : 'mb-0'">
                A última data cadastrada, será considerada a próxima data de
                início.
              </p>
              <b-form
                class="repeater-form"
                @submit.prevent="repeateAgainPostponements"
              >
                <app-timeline>
                  <div
                    v-for="(element, index) in item.postponements"
                    :id="`post-${index}`"
                    :key="index"
                  >
                    <app-timeline-item icon="calendar-event">
                      <div class="form-row">
                        <b-col md="9" lg="9">
                          <div class="form-group mb-1">
                            <flat-pickr
                              :disabled="
                                index !== item.postponements.length - 1
                                  ? true
                                  : false
                              "
                              v-model="element.date"
                              class="form-control"
                              :class="
                                index !== item.postponements.length - 1
                                  ? 'disabled'
                                  : ''
                              "
                              :config="{
                                altInput: true,
                                dateFormat: 'Y-m-d',
                                altFormat: 'd/m/Y',
                                locale: 'pt',
                              }"
                            />
                          </div>
                        </b-col>

                        <b-col
                          v-if="index === item.postponements.length - 1"
                          lg="3"
                          md="3"
                        >
                          <div class="form-group mb-0">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0"
                              block
                              @click="removeItemPostponements(index)"
                              style="line-height: 1.3"
                            >
                              <i class="bi bi-x-square"></i>
                            </b-button>
                          </div>
                        </b-col>
                      </div>
                    </app-timeline-item>
                  </div>
                </app-timeline>
              </b-form>
            </b-card-text>
          </b-card>

          <ButtonsActionsFooter
            routerBack="classrooms-list"
            variant="success"
            :submited="submited"
            text="Cadastrar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardFooter,
  BCardHeader,
  VBTooltip,
  BFormCheckbox,
  VBToggle,
  BButton,
  BModal,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BBadge,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { required, url, minLength, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    ButtonsActionsFooter,
    BSpinner,
    flatPickr,
    BModal,
    BFormCheckbox,
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardText,
    BCardFooter,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
    VBTooltip,
    BBadge,
  },
  data() {
    return {
      nextIdPostponements: 0,
      nextIdClassrooms: 0,
      submited: false,
      discipline: "",
      payment_forms: [
        {
          code: "money",
          icon: "bi-cash-stack",
          title: "Dinheiro",
        },
        {
          code: "card_debit",
          icon: "bi-credit-card-2-front",
          title: "Débito",
        },
        {
          code: "card_credit",
          icon: "bi-credit-card-2-back",
          title: "Crédito",
        },
        {
          code: "pix",
          icon: "bi-qr-code-scan",
          title: "PIX",
        },
        {
          code: "check",
          icon: "bi-card-text",
          title: "Cheque",
        },
        {
          code: "letter",
          icon: "bi-file-earmark-medical",
          title: "Carta de Crédito",
        },
        {
          code: "billet",
          icon: "bi-upc-scan",
          title: "Boleto",
        },
      ],
      item: {
        name: "",
        link: "",
        modality: "",
        start_time: "",
        end_time: "",
        interval: "00:15",
        grid_consys: "",
        course: "",
        date: "",
        display_panel: false,
        unit: "",
        status: { title: "Ativo", code: 1 },
        disciplines: [],
        postponements: [],
        classrooms: [],
        weekdays: [],
      },
      course: "",
      installments: [],
      optionsModalities: [],
      optionsCourses: [],
      optionsUnits: [],
      optionsTagTema: [],
      optionsShifts: [],
      optionsWorkDays: [],
      date_end: "",
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      course: {
        required,
      },
      modality: {
        required,
      },
      start_time: {
        required,
        minLength: minLength(5),
      },
      end_time: {
        required,
        minLength: minLength(5),
      },
      interval: {
        required,
        minLength: minLength(5),
      },
      status: {
        required,
      },
      postponements: {
        required,
      },
      unit: {
        required: requiredIf((vm) => {
          return vm.modality.title === "Presencial" ? true : false;
        }),
      },
      link: {
        url,
        required: requiredIf((vm) => {
          return vm.modality.title === "Live" ? true : false;
        }),
      },
      shift: {
        required,
      },
      weekdays: {
        required,
      },
    },
  },
  methods: {
    async courseSelected(value) {
      await this.$store.dispatch("Course/fetchShow", value.code).then((res) => {
        this.course = res;
      });
    },
    repeateAgainClassrooms() {
      this.nextIdClassrooms = this.nextIdClassrooms + 1;
      this.item.classrooms.push({
        id: this.nextIdClassrooms,
      });
    },
    removeItemClassrooms(index) {
      this.item.classrooms.splice(index, 1);
    },
    repeateAgainPostponements() {
      this.nextIdPostponements = this.nextIdPostponements + 1;
      this.item.postponements.push({
        id: this.nextIdPostponements,
      });
    },
    removeItemPostponements(index) {
      this.item.postponements.splice(index, 1);
    },
    async fetchCourse(term) {
      this.optionsCourses = [];
      if (term.length > 2) {
        this.optionsCourses = await this.$store.dispatch(
          "Course/fetchSearchStatus",
          { term, status: 1 }
        );
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        this.$store
          .dispatch("Classroom/create", this.item)
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "classrooms-list" });
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async mounted() {
    this.optionsModalities = await this.$store.dispatch(
      "Classroom/forSelectTypes"
    );
    this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect", 1);
    this.optionsWorkDays = await this.$store.dispatch("Classroom/weekdays");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.area-lessons {
  min-height: 400px;
}

.list-disciplines {
  .d-flex-between-full:last-child {
    padding-bottom: 0px !important;
  }
}
</style>
